<template>
  <div class="c-audio-element">
    <div class="c-audio-box__col u-px u-py">
      <picture
        class="c-audio-box__pic">
        <Headphones class="c-audio-box__img"/>
      </picture>
    </div>
    <div class="c-audio-box__col c-audio-box__col--title u-pr u-py u-px">
      <VHeading header="h4"
        class="c-audio-box__subtitle">
        {{ $t('AUDIOTYPE_NAME') }}
      </VHeading>
      <VHeading header="h2"
        class="c-audio-box__title"
        :text="content.audio_type.name" />
      <FHButton class="c-article-box__btn"
        :href="`/lesson/${unit.slug}/${lesson.slug}?tab=${content.audio_type.name.toLowerCase().replace(/ /g, '-')}`">
        {{ $t('ACTIVITY_BUTTON') }}
      </FHButton>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from '@vue/composition-api'
import VHeading from '@forlagshuset/v-heading'
import FHButton from '@forlagshuset/v-button'
import Headphones from '@/assets/svg/Headphones'

export default {
  components: {
    VHeading,
    FHButton,
    Headphones
  },

  props: {
    content: Object,
    unit: Object,
    lesson: Object
  },

  setup(props) {
    const article = computed(() => props.content.article_type || props.content)
    const image = ref('')

    onMounted(() => {
      console.log(props.content)
    })

    return {
      article,
      image
    }
  }
}
</script>

<style lang="scss">
.c-audio-element {
  position: relative;
  background-color: $color-white;
  color: $color-black;
  text-decoration: none;
  display: flex;
  text-align: left;
  margin: 0.5rem;
  border-radius: 1rem;
}
.c-audio-box__subtitle {
  line-height: 1.2rem;
  height: 2.4rem;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  text-transform: uppercase;
  color: $color-grey-lighter;
  h4 {
    margin: 0;
  }
}
.c-audio-box__title {
  h2 {
    margin: 0;
    font-size: 2rem;
    line-height: normal;
  }
}
.c-audio-box__desc {
  overflow: hidden;
  text-overflow: ellipsis; 
  max-height: 1.75rem;
  max-width: 100%;
  p {
    margin: 0;
  }
}

.c-audio-box__pic{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;
  border-radius: 1rem;
}

.c-audio-box__img{
  border-radius: 2rem;
  background-color: $color-black;
  padding: .5rem;
  width: 2rem;
  height: 2rem;
}
.c-audio-box__col {
  height: 100%;
}
.c-audio-box__btn {
  position: absolute;
  bottom: 1.5rem;
  background-color: $color-brand;
  color: $color-white;
  border-width: 0;
  font-family: CircularPro, sans-serif;
  font-weight: 400;
  border-radius: 2rem;
  padding: .75rem 2rem;
  display: block;

  &:hover {
    background-color: $color-brand-medium;
  }
}
</style>