<template>
  <div class="c-article-element">
    <div class="c-article-box__col u-px u-py">
      <picture v-if="image" class="c-article-box__pic">
        <img
          :src="`${image}`"
          :title="
            article.cover.image_type.license &&
            article.cover.image_type.license.credit
              ? article.cover.image_type.license.credit
              : ''
          "
          class="c-article-box__img"
        />
      </picture>
    </div>
    <div class="c-article-box__col c-article-box__col--title u-pr u-py u-px">
      <VHeading header="h4" class="c-article-box__subtitle">
        {{ unit.name }}: {{ lesson.name }}
      </VHeading>
      <VHeading header="h2" class="c-article-box__title" :text="article.name" />
      <FHButton
        class="c-article-box__btn"
        :href="
          `/lesson/${unit.slug}/${
            lesson.slug
          }?tab=${article.name.toLowerCase().replace(/ /g, '-')}`
        "
      >
        {{ $t('ACTIVITY_BUTTON') }}
      </FHButton>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from '@vue/composition-api'
import { addCloudinaryUrlParams } from '@/utils'
import VHeading from '@forlagshuset/v-heading'
import FHButton from '@forlagshuset/v-button'

export default {
  components: {
    VHeading,
    FHButton,
  },

  props: {
    content: Object,
    unit: Object,
    lesson: Object,
  },

  setup(props) {
    const article = computed(() => props.content.article_type || props.content)
    const image = ref('')

    onMounted(() => {
      if (article.value.cover) {
        const coverURL = article.value.cover.image_type.file.url
        const param = 'h_150,c_scale/w_150,h_150,c_crop'
        image.value = addCloudinaryUrlParams(coverURL, param)
      }
    })

    return {
      article,
      image,
    }
  },
}
</script>

<style lang="scss">
.c-article {
  &-element {
    position: relative;
    background-color: $color-white;
    color: $color-black;
    text-decoration: none;
    display: flex;
    min-height: 9.375rem;
    text-align: left;
    margin: 0.5rem;
    border-radius: 1rem;
    .c-text-box-element--factbox,
    .c-text-box-element--speechbubble p:first-of-type,
    .c-text-box-element--speechbubble p:first-of-type:before,
    .c-text-box-element--discuss {
      background: $color-brand;
    }
    .c-text-box-element--against {
      background: $color-brand-light;
      .c-article__section-text {
        p:first-of-type {
          background: $color-brand-light;

          [class*='c-activity--aktor-parent-'] & {
            background: transparent;
          }
        }
      }
    }
    .c-text-box-element--speechbubble {
      .c-article__section-text a {
        color: $color-black;
      }
    }
  }

  &__bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  &__container {
    position: relative;
    z-index: 1;
  }
  &-box {
    &__subtitle {
      line-height: 1.2rem;
      overflow: hidden;
      text-overflow: ellipsis;
      text-transform: uppercase;
      color: $color-grey-lighter;
      h4 {
        margin: 0;
      }
    }
    &__title {
      h2 {
        margin: 0;
        font-size: 2rem;
        line-height: normal;
      }
    }
    &__desc {
      overflow: hidden;
      text-overflow: ellipsis;
      max-height: 1.75rem;
      max-width: 100%;
      p {
        margin: 0;
      }
    }
    &__img {
      border-radius: 1rem;
    }
    &__col {
      height: 100%;
    }
    &__btn {
      position: absolute;
      bottom: 1.5rem;
      background-color: $color-brand;
      color: $color-white;
      border-width: 0;
      font-family: CircularPro, sans-serif;
      font-weight: 400;
      border-radius: 2rem;
      padding: 0.75rem 2rem;
      display: block;

      &:hover {
        background-color: $color-brand-medium;
      }
    }
  }
}
</style>
