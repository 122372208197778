<template>
  <FHTabs
    :tabable="true"
    ref="tabs"
    @changed="onChangeTab">
    <template #before-tablist>
      <div class="c-tabs__tablist-label">
        {{ $t('UNIT_TABS_LABEL') }}:
      </div>
    </template>
    <FHTab v-for="theme in themes"
      :key="theme.id"
      :name="theme.name">
      <Lesson :lesson="theme"
        :unit="unit"
        :subtitle="'Fagstoff'"
        class="c-unit__lesson o-wrapper u-py u-px--3"
      />
    </FHTab>
  </FHTabs>
</template>

<script>
import { FHTabs, FHTab } from '@forlagshuset/v-tabs'
import Lesson from '@/components/Lesson'

export default {
  name: 'ThemeTabs',

  components: {
    FHTabs,
    FHTab,
    Lesson
  },

  props: {
    themes: Array,
    unit: Object
  },

 setup(props, ctx) {
    const onChangeTab = (e) => {
      ctx.emit('theme-tag', e)
    }

    return {
      onChangeTab
    }
  }
}
</script>