<template>
  <div class="c-lesson__wrapper">
    <div class="c-lesson__container u-px">
      <VHeading v-if="focus" header="h3" class="o-heading">
          {{ $t('LESSON_FOCUS', { count: focus }) }}
      </VHeading>
      <VHeading header="h2" class="o-heading">
        {{ lesson.name }}
      </VHeading>
      <VHeading v-if="subtitle" header="h4" class="c-lesson__subtitle">
        {{ subtitle }}
      </VHeading>
      <div class="c-lesson__content">
        <AudioElement v-if="lesson.audio"
          :content="lesson.audio"
          class="c-lesson__audio-player" />
        <div class="c-lesson__description"
          v-md-html="lesson.description" />
        <div v-if="loadingLE && lesson.concepts"
          class="c-lesson__concepts">
          <VHeading header="h3" class="o-heading"
            :text="$t('CONCEPTS_HEADER')">
          </VHeading>
          <component v-for="item in lesson.concepts"
            :key="item._id"
            :is="componentNameNormalize(item.__components)"
            :content="item"
            :can-open-modal="true"
            class="c-lesson__concepts-item"></component>
        </div>
      </div>
      <div v-if="isTeacher && lesson.teacherLE" class="c-lesson__teachers-resources u-mb">
        <component v-for="item in lesson.scenario"
          :key="item._id"
          :is="componentNameNormalize(item.__component)"
          :content="item"
          :unit="unit"
          :lesson="lesson"
          class="c-lesson__resources-item u-mb"></component>
      </div>
      <div v-if="isTeacher && loadingTLE" class="c-lesson__teachers-resources u-mb">
        <div class="c-section-tag u-tac c-lesson__resources-item">
          <ContentLoader>
            <rect x="15%" y="2rem" width="70%" height="3rem"></rect>
          </ContentLoader>
        </div>
        <div class="c-activity-element u-mb c-lesson__resources-item u-mr--2">
          <ContentLoader>
            <rect x="0" y="1.5rem" width="100%" height="3rem"></rect>
          </ContentLoader>
          <a class="c-activity-element__btn c-button c-button--link" />
        </div>
      </div>
    </div>
      <div v-if="!loadingLE" class="c-lesson__resources">
        <component v-for="item in lesson.learningElementSection"
          :key="item._id"
          :is="componentNameNormalize(item.__component)"
          :content="item"
          :unit="unit"
          :lesson="lesson"
          class="c-lesson__resources-item u-mr--2">
        </component>
      </div>
      <div v-else class="c-lesson__resources">
        <div class="c-section-tag u-tac c-lesson__resources-item u-mr--2">
          <ContentLoader>
            <rect x="15%" y="2rem" width="70%" height="3rem"></rect>
          </ContentLoader>
        </div>
        <div class="c-activity-element u-mb c-lesson__resources-item u-mr--2">
          <ContentLoader>
            <rect x="0" y="1.5rem" width="75%" height="3rem"></rect>
          </ContentLoader>
          <a class="c-activity-element__btn c-button c-button--link" />
        </div>
        <div class="c-activity-element u-mb c-lesson__resources-item u-mr--2">
          <ContentLoader>
            <rect x="0" y="1.5rem" width="75%" height="3rem"></rect>
          </ContentLoader>
          <a class="c-activity-element__btn c-button c-button--link" />
        </div>
        <div class="c-section-tag u-tac c-lesson__resources-item u-mr--2">
          <ContentLoader>
            <rect x="15%" y="2rem" width="70%" height="3rem"></rect>
          </ContentLoader>
        </div>
        <div class="c-activity-element u-mb c-lesson__resources-item u-mr--2">
          <ContentLoader>
            <rect x="0" y="1.5rem" width="75%" height="3rem"></rect>
          </ContentLoader>
          <a class="c-activity-element__btn c-button c-button--link" />
        </div>
        <div class="c-activity-element u-mb c-lesson__resources-item u-mr--2">
          <ContentLoader>
            <rect x="0" y="1.5rem" width="75%" height="3rem"></rect>
          </ContentLoader>
          <a class="c-activity-element__btn c-button c-button--link" />
        </div>
      </div>
    </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import { componentNameNormalize } from '@/utils'
import { ContentLoader } from 'vue-content-loader'
import VHeading from '@forlagshuset/v-heading'
import Logotype from '@/assets/svg/Logotype'
import ComponentLearningElementSectionTagElement from '@/components/SectionTagElement'
import ComponentLearningElementSectionEndTagElement from '@/components/SectionEndTagElement'
import ComponentLearningElementActivityElement from '@/components/ActivityElement'
import ComponentLearningElementConceptElement from '@/components/ConceptElement'
import ComponentLearningElementArticleElement from '@/components/ArticleElement'
import AudioElement from '@/components/AudioElement'
import ComponentLearningElementAudioElement from '@/components/AudioBox'

export default {
  components: {
    VHeading,
    Logotype,
    ComponentLearningElementSectionTagElement,
    ComponentLearningElementSectionEndTagElement,
    ComponentLearningElementActivityElement,
    ComponentLearningElementConceptElement,
    ComponentLearningElementArticleElement,
    AudioElement,
    ContentLoader,
    ComponentLearningElementAudioElement
  },

  props: {
    lesson: Object,
    unit: Object,
    focus: {
      type: Number,
    },
    subtitle: {
      type: String,
      require: false,
      default: null,
    },
    loadingLE: {
      default: false,
      type: Boolean
    },
    loadingTLE: {
      default: false,
      type: Boolean
    },
  },

  setup (props, vm) {
    const isTeacher = computed(() => vm.root.$store.getters['appConfig/isTeacher'])

    return {
      componentNameNormalize,
      isTeacher
    }
  }
}
</script>

<style lang="scss">
.c-lesson__wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
}
.c-lesson__bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.c-lesson__container {
  position: relative;
  z-index: 1;
  padding-top: 2rem;

  h2 {
    margin-top: 0.3rem;
  }
}

.c-lesson__subtitle {
  text-transform: uppercase;
  color: $color-grey-lighter;
  h4 {
    margin: 0;
  }
}

.c-lesson__content {
  display: flex;
  flex-direction: row;
}
.c-lesson__audio-player {
  flex: 0 0 100%;
}
.c-lesson__description {
  display: block;
  font-size: 1.2rem;
}
.c-lesson__concepts {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 2;
  max-width: 65%;
}
.c-lesson__concepts {
  flex: 1;
}
.c-lesson__resources,
.c-lesson__teachers-resources {
  @include bp(940px) {
    text-align: left;
  }

  .c-section-tag,
  .c-activity-element {
    > svg {
      height: 4rem;
      width: 100%;
    }
  }
}

.c-lesson__teachers-resources {
  max-width: 805px;
  grid-template-columns: 1fr fit-content(330px);
  grid-auto-flow: row;
  grid-template-rows: auto;

  .c-section-tag {
    grid-row-start: auto;
    grid-column-start: 1;
    grid-column-end: none;
  }

  .c-activity-element {

    .c-activity-element__btn {
      font-family: CircularPro, sans-serif;
      background-color: $color-white;
    }
    &:nth-of-type(2n) {
      grid-column-start: 1;
    }
  }
}
</style>