<template>
  <div class="c-textbox"
    :class="{ 'c-textbox--opened': isOpened }">

    <div class="c-textbox__exercise u-px u-py">
      <FHButton v-if="title"
        class="c-textbox__toggler"
        tabindex="0"
        @keyup.enter="isOpened = !isOpened"
        @click.native="isOpened = !isOpened">
        <VHeading class="c-textbox__toggler-header"
          header="h2"
          :text="title" />
      </FHButton>
      <div v-if="exercise"
        v-show="isOpened"
        v-md-html="exercise"
        class="c-textbox__exercise-container u-px" />
      <ContentLoader v-else height="75">
        <rect x="0" y=".75rem" width="95%" height=".5rem"></rect>
        <rect x="0" y="1.5rem" width="60%" height=".5rem"></rect>
        <rect x="0" y="2.25rem" width="75%" height=".5rem"></rect>
        <rect x="0" y="3rem" width="45%" height=".5rem"></rect>
      </ContentLoader>
    </div>
    <div v-if="goal"
      v-show="isOpened"
      class="c-textbox__goals"
      v-md-html="goal" />
    <div v-else class="c-textbox__goals">
      <ContentLoader height="25">
        <rect x="0" y="0" width="100%" height=".5rem"></rect>
        <rect x="0" y=".75rem" width="45%" height=".5rem"></rect>
      </ContentLoader>
    </div>
  </div>
</template>

<script>
import { ContentLoader } from 'vue-content-loader'
import VHeading from '@forlagshuset/v-heading'
import FHButton from '@forlagshuset/v-button'

export default {
  name: 'TextBox',

  components: {
    ContentLoader,
    VHeading,
    FHButton
  },

  data() {
    return {
      isOpened: this.title ? false : true
    }
  },

  props: {
    exercise: String,
    goal: String,
    title: String
  }
}
</script>

<style lang="scss">
.c-textbox {
  font-size: 1.2rem;
  width: calc(100% -  1rem);

  p {
    margin: 0.5rem 0;
  }

  a {
    color: $color-white;
    font-weight: 700;
  }

  &--opened {
    .c-textbox__toggler {
      .c-textbox__toggler-header {
        &:after {
          transform: rotate(-135deg);
          -webkit-transform: rotate(-135deg);
          top: 0.75rem;
        }
      }
    }
  }
}

.c-textbox__exercise {
  position: relative;
  background-color: $color-white;
  border-radius: 1.2rem;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5);

  color: #fff;
  transition-duration: .5s;

  p {
    font-weight: 400;
  }
  a {
    font-size: 1rem;
  }
  z-index: 1;
}

.c-textbox__toggler {
  position: relative;
  background-color: transparent;
  border-color: transparent;
  width: 100%;
  text-align: left;
  padding-right: 3rem;
  .c-textbox__toggler-header {
    h2 {
      font-size: 1.2rem;
      margin: 0;
    }

    &:after {
      content: '';
      border: solid #fff;
      border-width: 0 .25rem .25rem 0;
      position: absolute;
      padding: .5rem;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      right: 1rem;
      top: 0;
    }
  }
}

.c-textbox__goals {
  background-color: $color-white;
  border-radius: 1.2rem;
  box-shadow: 0 2px 5px 0 rgba(35, 35, 35, 0.1);

  padding: 4.5rem 2rem 1rem;
  transform: translateY(-3rem);
  z-index: 0;
}
</style>
