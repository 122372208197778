<template>
  <div class="c-activity-element">
    <div class="c-activity-element__content">
      <VHeading header="h4" class="c-activity-element__title">
        {{ activity.name }}
      </VHeading>
      <div v-if="getTags.length"
        :class="getTags.length > 0 ? 'c-activity-element__tags':'c-activity-element__no-tags' " >
          <ul>
            <li v-for="tag in getTags" :key="tag.id"> {{ '#' + tag.label }} </li>
          </ul>
      </div>
      <div v-else
        class="c-activity-element__no-tags"
      />
    </div>

    <FHButton class="c-activity-element__btn"
      :tabindex="isDisabled ? '-1' : '0'"
      :class="{ 'c-activity-element__btn--disabled': isDisabled }"
      :href="`/activity/${unit.slug}/${lesson.slug}/${activity.slug}`">
      {{ $t('ACTIVITY_BUTTON') }}
    </FHButton>
  </div>
</template>

<script>
import { computed, onMounted, ref } from '@vue/composition-api'
import VHeading from '@forlagshuset/v-heading'
import FHButton from '@forlagshuset/v-button'

export default {

  components: {
    VHeading,
    FHButton
  },

  props: {
    content: Object,
    unit: Object,
    lesson: Object,
    themeId: String,
  },

  setup(props, ctx) {
    const activity = computed(() => props.content.activity_type || props.content)
    const isDisabled = computed(() => {
      if(!props.themeId) return false

      return activity.value.tags.indexOf(props.themeId) === -1
    })

    const getTags = ref([])

    onMounted(() => {
      activity.value.tags.forEach(async tag => {
        const t = ctx.root.$store.getters['content/getTag'](props.unit.slug, tag)
        if(
          t &&
          (t.label.indexOf('-tema-') === -1) &&
          (t.label.indexOf(props.lesson.name) === -1)
        ) {
          getTags.value.push({ id: tag, label: t.label})
        }
      })
    })

    return {
      activity,
      isDisabled,
      getTags,
    }
  }
}
</script>

<style lang="scss">
.c-activity-element {
  display: flex;
  flex-direction: row;
  font-size: 1.25rem;
  padding: 1.125rem 0;
  max-height: 3.725rem;
  overflow-y: hidden;
}
.c-activity-element__content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-self: center;
}
.c-activity-element__title {
  h4 {
    font-size: 1.395rem;
    font-weight: 400;
    margin: 0;
  }
}
.c-activity-element {
  &__tags{
    color: $color-grey-lighter;
    width: 90%;
    font-size: 0.7rem;
    height: 2.2em;
    flex-wrap: nowrap;
    line-height: .8rem;
    overflow: hidden;

    ul{
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        margin-right: .2rem;
        display: inline-block;
      }
    }
  }
  &__no-tags{
    display: none;
  }
}
.c-activity-element__btn {
  color: $activity-button-text;
  border-radius: 2rem;
  margin: .5rem 0 .5rem auto;
  padding: 0 2rem;
  font-family: CircularPro, sans-serif;
  font-size: 1.125rem;
  line-height: 2.75rem;
  background-color: $color-brand;

  &--disabled {
    background-color: $color-light-mid-grey !important;
    pointer-events: none;
  }
}
</style>