<template>
  <div :class="['c-unit', `c-unit--${parentUnit}`]">
    <FHBreadcrumbs v-if="crumbs"
      class="c-unit__breadcrumbs u-py u-px"
      :crumbs="crumbs" />

    <Banner v-if="cover"
      :image-url="cover.imageURL"
      :image-alt="cover.altText"
      :image-credit="cover.imageCredit"
      :image-meta="cover.file"
      />
    <ContentLoader v-else height="150">
      <rect x="0" y="0" width="100%" height="100%"></rect>
    </ContentLoader>

    <UnitHeader v-if="unit.name"
      :title="unit.name"
      :audio="audio"
      :description="description"
      :exercise="{
        exerciseTitle: exerciseTitle,
        exerciseText: exerciseText,
        goalText: goalText
      }"
      :learning-goals="learningGoals"
      :articles="articles"
      :unitSlug="unit.slug"
    />
    <ContentLoader v-else>
      <rect x="1rem" y="1rem" width="50%" height="1rem"></rect>
      <rect x="73%" y="1rem" width="25%" height="1.25rem"></rect>
      <rect x="73%" y="2.75rem" width="25%" height="1.25rem"></rect>
      <rect x="1rem" y="2.5rem" width="60%" height=".5rem"></rect>
      <rect x="1rem" y="3.25rem" width="60%" height=".5rem"></rect>
      <rect x="1rem" y="4rem" width="45%" height=".5rem"></rect>
    </ContentLoader>

    <div class="c-lessons-wrapper">
      <VHeading header="h2"
        class="c-lessons-wrapper__title o-heading">
        {{ $t('LESSON_HEADER')  }}
      </VHeading>
    </div>

    <div class="c-lessons-wrapper  o-wrapper">
      <div v-if="lessons.length"
        class="c-lessons__container  o-wrapper">
        <div v-for="(lesson, i) in lessons"
          :key="`lh-${lesson.id}`"
          class="c-lesson__wrapper">
          <div class="c-lesson__container">
            <VHeading header="h3" class="o-heading">
              {{ $t('LESSON_FOCUS', { count: i + 1 }) }}
            </VHeading>
            <VHeading header="h2" class="o-heading">
              {{ lesson.name }}
            </VHeading>
            <div class="c-lesson__content">
                <div class="c-lesson__description"
                v-md-html="lesson.description" />
            </div>
          </div>
        </div>
        <div v-for="lesson in lessons"
          :key="`lw-${lesson.id}`"
          class="c-lesson__wrapper">
          <div v-if="!loadingLE" class="c-lesson__resources">
            <component v-for="item in lesson.LES"
              :key="item._id"
              :is="componentNameNormalize(item.__component)"
              :content="item"
              :unit="unit"
              :lesson="lesson"
              class="c-lesson__resources-item">
            </component>
          </div>
          <div v-else class="c-lesson__resources">
            <div class="c-activity-element u-mb c-lesson__resources-item u-mr--2">
              <ContentLoader>
                <rect x="0" y="1.5rem" width="85%" height="3rem"></rect>
              </ContentLoader>
              <a class="c-activity-element__btn c-button c-button--link" />
            </div>
            <div class="c-activity-element u-mb c-lesson__resources-item u-mr--2">
              <ContentLoader>
                <rect x="0" y="1.5rem" width="85%" height="3rem"></rect>
              </ContentLoader>
              <a class="c-activity-element__btn c-button c-button--link" />
            </div>
          </div>

          <div v-if="isTeacher && !loadingTLE"
            class="c-lesson__teachers-resources">
            <component v-for="item in lesson.TLES"
              :key="item._id"
              :is="componentNameNormalize(item.__component)"
              :content="item"
              :unit="unit"
              :lesson="lesson"
              class="c-lesson__resources-item"></component>
          </div>
        </div>
      </div>

      <div v-else
        class="c-lessons__container o-wrapper">
        <ContentLoader>
          <rect x="1rem" y="1rem" width="50%" height="1rem"></rect>
          <rect x="1rem" y="2.5rem" width="60%" height=".5rem"></rect>
          <rect x="1rem" y="3.25rem" width="60%" height=".5rem"></rect>
          <rect x="1rem" y="4rem" width="45%" height=".5rem"></rect>
        </ContentLoader>
      </div>
    </div>

    <!-- While loading themes -->
    <div v-if="loadingThemes"
      class="c-tabs__wrapper  o-wrapper">
      <div class="c-tabs__list-wrapper">
        <div class="c-tabs__tablist-label">
          {{ $t('UNIT_TABS_LABEL') }}:
        </div>
        <ul role="tablist" class="c-tabs">
          <li role="presentation" class="c-tabs__item">
          </li>
        </ul>
      </div>
      <div class="c-tabs__panels-wrapper ">
        <div class="c-tabs__panels">
          <section role="tabpanel" class="c-tab__panel o-wrapper">
            <div class="c-lesson__wrapper c-unit__lesson o-wrapper">
              <div class="c-lesson__container">
                <ContentLoader>
                  <rect x="1rem" y="1rem" width="50%" height="1rem"></rect>
                  <rect x="1rem" y="2.5rem" width="60%" height=".5rem"></rect>
                  <rect x="1rem" y="3.25rem" width="60%" height=".5rem"></rect>
                  <rect x="1rem" y="4rem" width="45%" height=".5rem"></rect>
                </ContentLoader>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>

  <!-- When all themes are loaded -->
    <ThemeTabs v-if="!loadingThemes"
      @theme-tag="onChangeTheme"
      :themes="themes"
      :unit="unit" />

    <div v-if="lessons" class="c-lessons__container o-wrapper c-activities">
      <div class="c-activities-focus"
        v-for="(lesson, i) in lessons"
        :key="'activities-'+lesson.id">
        <VHeading header="h3" class="o-heading">
            {{ $t('LESSON_FOCUS', { count: i + 1 }) }}
        </VHeading>
        <VHeading header="h2" class="o-heading">
          {{ lesson.name }}
        </VHeading>
        <div v-if="lesson.activities && lesson.activities.length"
          class="c-activities-focus__container">
          <ComponentLearningElementActivityElement v-for="item in lesson.activities"
            :key="item.id"
            :content="item"
            :unit="unit"
            :lesson="lesson"
            :theme-id="selectedThemeTag"
            class="c-lesson__resources-item" />
        </div>
        <div v-else class="c-activities-focus__container">
          <div class="c-activity-element u-mb c-lesson__resources-item u-mr--2">
            <ContentLoader>
              <rect x="0" y="1.5rem" width="85%" height="3rem"></rect>
            </ContentLoader>
            <a class="c-activity-element__btn c-button c-button--link" />
          </div>
          <div class="c-activity-element u-mb c-lesson__resources-item u-mr--2">
            <ContentLoader>
              <rect x="0" y="1.5rem" width="85%" height="3rem"></rect>
            </ContentLoader>
            <a class="c-activity-element__btn c-button c-button--link" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from '@vue/composition-api'
import { ContentLoader } from 'vue-content-loader'
import { componentNameNormalize } from '@/utils'
import VHeading from '@forlagshuset/v-heading'
import FHBreadcrumbs from '@forlagshuset/v-breadcrumbs'
import Banner from '@/components/Banner'
import UnitHeader from '@/components/UnitHeader'
import ThemeTabs from '@/components/ThemeTabs'
import ComponentLearningElementActivityElement from '@/components/ActivityElement'


export default {
  name: 'Unit',

  components: {
    ContentLoader,
    Banner,
    UnitHeader,
    VHeading,
    FHBreadcrumbs,
    ThemeTabs,
    ComponentLearningElementActivityElement,
  },

  setup(props, ctx) {
    const appSlug = ctx.root.$store.getters['appConfig/getLocale'].slug
    const unitSlug = computed(() => ctx.root.$options.router.history.current.params.unitSlug)
    const lessons = ref([])
    const themes  =ref([])
    const cover = ref(null)
    const description = ref('')
    const audio = ref(null)
    const exerciseTitle = ref('')
    const exerciseText = ref('')
    const goalText = ref('')
    const learningGoals = ref([])
    const articles = ref([])
    const parentUnit = ref('')
    const crumbs = ref([])
    const onChangeTab = ref(() => {})
    const tabs = ref(null)
    const unit = ref({})
    const firstLesson = ref(null)
    const loadingTLE = ref(true)
    const loadingLE = ref(true)
    const loadingThemes = ref(true)
    const activities = ref([])
    const selectedThemeTag = ref(null)
    const showIntro = ref(false)
    const onChangeTheme = (e) => {
      const selectedTheme = themes.value.filter(t => t.name === e.tab.name)[0]
      selectedThemeTag.value = selectedTheme.tags.filter(tag => tag.label.indexOf('-tema-') > -1)[0].id
    }

    const isTeacher = computed(() => ctx.root.$store.getters['appConfig/isTeacher'])

    if (!ctx.root.$options.router.history.current.query.tab) {
      onChangeTab.value = selectedTab => {
        ctx.root.$router.push({query: {tab: selectedTab.tab.$el.id}})
      }
    }

    onMounted (async () => {
      await ctx.root.$store.dispatch('content/setUnits', appSlug)
      unit.value = await ctx.root.$store.getters['content/getUnit'](unitSlug.value)

      crumbs.value.push({
        type: 'unit',
        name: unit.value.name,
        slug: unit.value.slug
      })

      if (unit.value.cover && unit.value.cover.image_type) {
        cover.value = {}
        cover.value.imageURL = unit.value.cover.image_type.file.url
        cover.value.altText = unit.value.cover.image_type.altText
        cover.value.imageCredit = unit.value.cover.image_type.license ? unit.value.cover.image_type.license.credit : ''
        cover.value.file = unit.value.cover.image_type.file
      }

      await ctx.root.$store.dispatch('content/setUnitParsedContent', { slug: unitSlug.value, content: unit.value.content })
      const unitContent = await ctx.root.$store.getters['content/getUnitParsedContent'](unitSlug.value)
      const aktorBox = unitContent.filter(item => item.__component === 'AktorBox')[0]
      parentUnit.value = unit.value.parent.slug

      exerciseTitle.value = aktorBox ? aktorBox.name : ''
      exerciseText.value = aktorBox ? aktorBox.items[0].body : ''
      goalText.value = aktorBox ? aktorBox.items[1].content : ''

      audio.value = unitContent.filter(item => componentNameNormalize(item.__component) === 'ComponentLearningElementAudioElement')[0]
      description.value = unitContent.filter(item => componentNameNormalize(item.__component) === 'ComponentLearningElementTextBox')[0].content
      learningGoals.value = unit.value.learningGoals
      articles.value = unitContent.filter(item => componentNameNormalize(item.__component) === 'ComponentLearningElementArticleElement')

      await ctx.root.$store.dispatch('content/setLessons', unitSlug.value)
      lessons.value = await ctx.root.$store.getters['content/getLessons'](unitSlug.value)
      await Promise.all(lessons.value.map(async (lesson, i) => {
        lesson.LES = lesson.learningElementSection.filter(el => componentNameNormalize(el.__component) === 'ComponentLearningElementActivityElement')

        lesson.TLES = lesson.scenario.filter(el => componentNameNormalize(el.__component) === 'ComponentLearningElementActivityElement')

        //pull focus activities
        if (lesson.keywords && lesson.keywords[0]) {
          await ctx.root.$store.dispatch('content/setLessonActivities', { unitSlug: unitSlug.value, lessonSlug: lesson.slug })
          const activities = await ctx.root.$store.getters['content/getLessonActivities'](unitSlug.value, lesson.slug)
          lesson.activities =  activities
            .map(a => {
              a.index = +a.name.split('.').pop()
              return a
            })
            .sort((a,b) => a.index - b.index)
        }

        if (i === (lessons.value.length - 1)) {
          loadingLE.value = false
          loadingTLE.value = false
        }

        return
      }))

      //search themes
      if (unit.value.keywords.length) {
        const themaUnit = appSlug === 'aktor' ? 'aktor-tema' : 'aktor-tema-nn'
        await ctx.root.$store.dispatch('content/setLessons', themaUnit)
        const allThemes = await ctx.root.$store.getters['content/getLessons'](themaUnit)
          .map(th => {
            th.tagIds = th.tags.map(tag => tag.id)
            return th
          })
        themes.value = allThemes.filter(theme => theme.tagIds.includes(unit.value.keywords[0].id))
      }
      selectedThemeTag.value = themes.value[0].tags.filter(tag => tag.label.indexOf('-tema-') > -1)[0].id
      loadingThemes.value = false

      if (ctx.root.$options.router.history.current.query.tab) {
        ctx.root.$nextTick(() => {
          ctx.refs.tabs.setActive(`#${ctx.root.$options.router.history.current.query.tab}`)
          onChangeTab.value = selectedTab => {
            ctx.root.$router.push({query: {tab: selectedTab.tab.$el.id}})
            tabs.value.$el.scrollIntoView({behavior: "smooth"})
          }
        })
      }
    })

    return {
      appSlug,
      unit,
      unitSlug,
      lessons,
      themes,
      cover,
      audio,
      description,
      exerciseTitle,
      exerciseText,
      goalText,
      learningGoals,
      articles,
      parentUnit,
      crumbs,
      onChangeTab,
      tabs,
      firstLesson,
      loadingThemes,
      activities,
      selectedThemeTag,
      onChangeTheme,
      showIntro,
      isTeacher,
      loadingTLE,
      loadingLE,
      componentNameNormalize
    }
  },
}
</script>

<style lang="scss">
.c-unit {
  &[class*="c-unit--aktor-parent-1"] {
    background: $unit-1-color-brand-light;
    color: $color-black;
    .c-textbox__exercise,
    .c-tabs__btn--active,
    .c-tabs__btn--active:before,
    .c-article-box__btn,
    .c-audio-box__pic {
      background: $unit-1-color-brand;
    }
    .c-lesson__teachers-resources .c-activity-element__btn {
      color: $unit-1-color-brand;
    }
    .c-tabs__btn {
      border-color: $unit-1-color-brand;
      &:hover {
        background: $unit-1-color-brand;
        color: $color-white;
      }
    }
    .c-lesson__wrapper .c-activity-element__btn,
    .c-activities-focus .c-activity-element__btn,
    .c-article-box__btn {
      background: $unit-1-color-brand;
      &:hover {
        background-color: $unit-1-color-brand-medium;
      }
    }
    .c-activity-element__btn{
      background-color: $unit-1-color-brand;
    }
    .c-activity-element__btn--disabled{
      background-color: $color-light-mid-grey;
    }
  }
  &[class*="c-unit--aktor-parent-2"] {
    background: $unit-2-color-brand-light;
    color: $color-black;
    .c-textbox__exercise,
    .c-tabs__btn--active,
    .c-tabs__btn--active:before,
    .c-article-box__btn,
    .c-audio-box__pic {
      background: $unit-2-color-brand;
    }
    .c-tabs__btn {
      border-color: $unit-2-color-brand;
      &:hover {
        background: $unit-2-color-brand;
        color: $color-white;
      }
    }

    .c-lesson__wrapper .c-activity-element__btn,
    .c-activities-focus .c-activity-element__btn,
    .c-article-box__btn {
      background: $unit-2-color-brand;
      &:hover {
        background-color: $unit-2-color-brand-medium;
      }
    }
    .c-activity-element__btn--disabled{
      background-color: $color-light-mid-grey;
    }
  }
  &[class*="c-unit--aktor-parent-3"] {
    background: $unit-3-color-brand-light;
    color: $color-black;
    .c-textbox__exercise,
    .c-tabs__btn--active,
    .c-tabs__btn--active:before,
    .c-article-box__btn,
    .c-audio-box__pic {
      background: $unit-3-color-brand;
      color: $color-black;
    }
    .c-tabs__btn {
      border-color: $unit-3-color-brand;
      color: $color-black;
      &:hover {
        background: $unit-3-color-brand;
        color: $color-black;
      }
    }
    .c-lesson__wrapper .c-activity-element__btn,
    .c-activities-focus .c-activity-element__btn,
    .c-article-box__btn {
      background: $unit-3-color-brand;
      color: $color-black;
      &:hover {
        color: $color-black;
        background-color: $unit-3-color-brand-medium;
      }
    }
    .c-activity-element__btn{
      background-color: $unit-1-color-brand;
    }
    .c-activity-element__btn--disabled{
      background-color: $color-light-mid-grey;
    }
  }
  .c-tabs__wrapper {
    .c-tabs__btn {
      font-size: 0.775rem;
    }

    .c-lesson__container {
      h2 {
        font-size: 2.775rem;
      }
      h4 {
        font-size: 1.5rem;
        font-weight: 500;
        text-transform: none;
      }
    }

    .c-article-element {
      .c-article-box__subtitle {
        line-height: 1.125rem;
        height: auto;

        h4 {
          font-size: 0.775rem;
          line-height: normal;
        }
      }
      .c-article-box__title {
        h2 {
          font-size: 1.385rem;
          line-height: 1;
          margin-top: 0.25rem;
        }
      }
    }
  }
}

.c-unit__breadcrumbs {
  font-family: CircularPro, sans-serif;
  .c-breadcrumbs__home {
    display: none;
  }
  .c-breadcrumbs__link {
    color: $color-black;
    &:hover {
      text-decoration: none;
    }
  }
}

.c-lessons-wrapper {
  background: $color-white;
  border: 4px solid #F2F2F2;

  .o-wrapper {
    margin-left: 0;
  }

  .c-lessons__container {
    display: flex;
    flex-wrap: wrap;
    background: $body-bg;
    gap: 1rem 3.75rem;

    .c-lesson__wrapper {
      width: calc(33% - 3.5rem);

      &:nth-of-type(3n + 1) {
        margin-left: 2rem;
      }
    }
  }
  &__title {
    margin: 0 auto;
    max-width: 1280px;

    h2 {
      font-size: 2.125rem;
      font-weight: 500;
      margin: 0;
      padding: 1.25rem 2rem;
    }
  }
}

.c-activities {
  display: flex;
  flex-wrap: wrap;
  background: $body-bg;
}

.c-tabs__list-wrapper {
  border: 4px solid #F2F2F2;
  margin: 0 auto;
  background: $color-white;
}

.c-tab__panel{
  .c-lesson__wrapper {
    padding: 2rem;
  }
  .c-lesson__resources {
    max-width: inherit;
    display: flex;
    flex-wrap: wrap;

    .c-article-element {
      width: calc(50% - 1rem);
    }
  }
  .c-lesson__content {
    display: none;
  }
}

.c-tabs__panels-wrapper {
  max-width: 1280px;
  margin: 0 auto;
}
.c-textbox__toggler {
  background-color: transparent;
  border-color: transparent;
}
.c-textbox__toggler {
  background-color: transparent;
  border-color: transparent;
}
.c-activities-focus {
  width: calc(33.33% - 2.5rem);
  padding: 2rem 0 0 2rem;

  .c-header {
    h2, h3 {
      margin: 0;
    }
  }

  .c-activity-element {
    padding-left: 0;
  }
}
</style>
