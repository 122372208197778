<template>
  <div class="c-unit-header">
    <VHeading v-if="title"
      header="h1"
      class="c-unit__title o-wrapper o-heading">
      {{ title }}
    </VHeading>

    <div class="c-unit__container u-mb">
      <div class="o-wrapper">
        <AudioElement v-if="audio"
          :content="audio" />

        <div v-if="description"
          class="c-unit__description o-description"
          :class="{ 'c-unit__description-toggle': showIntro }">
          <div v-md-html="description"
            class="c-unit__description-text"></div>
          <FHButton v-if="!showIntro"
            class="c-unit__description-toggler u-mt"
            @click.native="showIntro = !showIntro">
            {{ $t('UNIT_READ_MORE') }}
          </FHButton>
        </div>

        <TheTextBox v-if="exercise.exerciseText"
          :title="exercise.exerciseTitle"
          :exercise="exercise.exerciseText"
          :goal="exercise.goalText"
          class="u-mb u-mr" />
      </div>
      <div class="c-unit__goals u-mr u-mb">
        <Goal v-if="articles.length"
          class="c-unit__single-goal c-unit__single-goal--right"
          :title="articles[0].name"
          :slug="articles[0].article_type.slug"
          :unit="unitSlug" />
        <div v-if="isTeacher">
          <div v-if="articles.length > 1">
            <Goal v-for="article in articles.slice(1)"
              class="c-unit__single-goal c-unit__single-goal--right"
              :key="article._id"
              :title="article.name"
              :slug="article.article_type.slug"
              :unit="unitSlug" />
          </div>
          <Goal v-for="goal in learningGoals"
            class="c-unit__single-goal"
            :key="goal._id"
            :title="goal.Label"
            :content="goal.Content" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import VHeading from '@forlagshuset/v-heading'
import FHButton from '@forlagshuset/v-button'
import AudioElement from '@/components/AudioElement'
import Goal from '@/components/Goal'
import TheTextBox from '@/components/TheTextBox'

export default {
  name: 'UnitHeader',

  components: {
    VHeading,
    FHButton,
    AudioElement,
    Goal,
    TheTextBox
  },

  data() {
    return {
      showIntro: false,
    }
  },

  props: {
    title: String,
    audio: Object,
    description: String,
    exercise: Object,
    learningGoals: Array,
    articles: Array,
    unitSlug: String
  },

  setup(props, ctx) {
    const isTeacher = computed(() => ctx.root.$store.getters['appConfig/isTeacher'])

    return {
      isTeacher
    }

  }
}
</script>

<style lang="scss">
.c-unit-header {
  padding-left: 5rem;
}
.c-unit__title {
  padding-left: 1rem;
  
  @include bp(large) {
    padding: 0;
  }

  h1 {
    font-size: 3.05rem;
  }
}
.c-unit__container {
  display: flex;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;

  .o-wrapper {
    margin-left: 0;
  }

  @include bp-down(850px) {
    flex-wrap: wrap;
    padding: 1.5rem;
    > div {
      width: 100%;
      order: 1;

      &:nth-child(2) {
        order: 0;
      }
    }
  }
}
.c-unit__description {
  position: relative;
  margin: 0 1rem 1rem 0;
  padding: 0;

  &-toggle {
    .c-unit__description-text {
      height: auto;
    }
  }
}
.c-unit__description-text {
  height: 6.785rem;
  padding-right: 2rem;
  overflow-y: hidden;
}
.c-unit__description-toggler {
  font-family: CircularPro, sans-serif;
  background: $color-white;
  box-shadow: 0.1rem 0px 0.75rem $box-shadow-color;
  border-radius: 1.3rem;
  padding: .5rem 2.5rem;
}
</style>