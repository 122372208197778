<template>
  <div class="c-goal__wrapper u-mb"
    :class="{ 'c-goal__wrapper--opened': isOpened }">
    <FHButton v-if="content"
      class="c-goal__toggler u-px u-py"
      @click.native="isOpened = !isOpened">
      {{ title }}
    </FHButton>
    <router-link
      v-else
      :to="{ name: 'Article', params: { unitSlug: unit, articleSlug: slug } }"
      class="c-goal__toggler u-px u-py">
      {{ title }}
    </router-link>
    <div v-if="content"
      v-show="isOpened"
      class="u-py u-px"
      v-md-html="content" />
  </div>
</template>

<script>
import FHButton from '@forlagshuset/v-button'

export default {
  components: {
    FHButton,
  },

  data () {
    return {
      isOpened: false
    }
  },

  props: {
    title: String,
    content: String,
    slug: String,
    unit: String,
  }
}
</script>

<style lang="scss">
.c-goal__wrapper {
  border-radius: 1rem;
  box-shadow: 0.1rem 0px 0.75rem $box-shadow-color;
  background: $color-white;
  @include bp(850px) {
    width: 310px;
  }
  &.c-goal__wrapper--opened {
    .c-goal__toggler {
      &:after {
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
      }
    }
  }
  &.c-unit__single-goal--right {
    .c-goal__toggler {
      &:after {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
      }
    }
  }
}
.c-goal__toggler {
  font-family: CircularPro, sans-serif;
  background: none;
  width: 100%;
  text-align: left;
  border: none;
  font-size: 1.2rem;
  display: block;
  box-sizing: border-box;
  position: relative;
  color: $color-black;
  text-decoration: none;
  &:after {
    content: '';
    border: solid #000;
    border-width: 0 .25rem .25rem 0;
    position: absolute;
    padding: .25rem;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    right: 1rem;
    top: 1.5rem;
  }
}
</style>